
.rec.rec-arrow{
    background: linear-gradient(25.52deg, rgba(27, 0, 105, 0.2) -7.65%, rgba(27, 0, 105, 0) 104.52%);
box-shadow: inset 0px 0px 40px rgba(255, 255, 255, 0.25);
backdrop-filter: blur(12.5px);
/* Note: backdrop-filter has minimal browser support */

border-radius: 40px;
/* margin: 0 1rem; */
}

.rec.rec-dot{
    background-color: transparent;
    border: 1px solid #FFFFFF;
    box-shadow: inset 0px 0px 40px rgba(255, 255, 255, 0.25);
backdrop-filter: blur(20px);

}
